import { Module } from 'vuex';
import { State } from '@/store/models';
import authModal from './authModal';
import idin from './idin';
import payment from './payment';
import investments from './investments';
import payments from './payments';
import invoices from './invoices';
import repayments from './repayments';
import assets from './assets';
import identificationChecks from './identificationChecks';
import downloads from './downloads/downloads';
import checkout from './checkout';
import customLogin from './customLogin';

export default <{ [key: string]: Module<any, State> }>{
  idin,
  payment,
  investments,
  payments,
  invoices,
  repayments,
  assets,
  identificationChecks,
  downloads,
  authModal,
  checkout,
  customLogin,
};
