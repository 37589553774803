// Not included in core-js https://github.com/zloirock/core-js/issues/386
// ------------ Polyfills ------------
// import 'core-js/stable';
import 'core-js/stable/object/assign';
import 'core-js/stable/array/values';
// IntersectionObserver polyfill for v-lazy-image
import 'intersection-observer';
// Rest
import { auth, db } from './firebase';
import vueStart from './vue';
import './icons/icons';

vueStart(auth, db);
