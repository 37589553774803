import { i18n } from '@/i18n';

// from https://stackoverflow.com/a/63176814/3900111
export function roundNumber(num, scale): number {
  // eslint-disable-next-line no-restricted-properties
  const precision = 10**scale;
  return Math.round((num+Number.EPSILON) * precision) / precision;
}

/**
 * Formatting numbers using i18n
 *
 * Accepts an optional precision parameter and an optional boolean localisation parameter
 * Note: Infinity returns the infinity symbol ∞ if localisation is turned on
 *
 * @example {{ myNumber | formatNumber }}
 * @example {{ myNumber | formatNumber(1) }}
 * @example {{ myNumber | formatNumber(, true) }}
 *
 */
export function formatNumber( // there is a number provided => we always get a string
  myNumber: number,
  precision: number | boolean,
  localisation?: boolean,
  undefinedNumber?: false | string | number,
): string
export function formatNumber( // there is at least a backup number provided => we always get a string
  myNumber: number | undefined,
  precision: number | boolean,
  localisation: boolean,
  undefinedNumber: string | number,
): string
export function formatNumber( // there is a number provided albeit no backup number => we always get a string
  myNumber: number,
  precision: number | boolean,
  localisation?: boolean,
  undefinedNumber?: undefined | false,
): string
export function formatNumber( // there is no necessarily a number provided nor a backup number => we are not sure
  myNumber: undefined | number,
  precision: number | boolean,
  localisation?: boolean,
  undefinedNumber?: undefined | false | never,
): undefined | string
export function formatNumber( // there is no number provided nor a backup number => we always get a undefined
  myNumber: undefined,
  precision: number | boolean,
  localisation?: boolean,
  undefinedNumber?: undefined | false | never,
): undefined
export function formatNumber(
  myNumber: number | undefined,
  precision: number | boolean = false,
  localisation: boolean = false,
  undefinedNumber: false | string | number = false,
  decimal: boolean = false,
): string | undefined {
  if (typeof myNumber !== 'number' || isNaN(myNumber)) return undefinedNumber !== undefined ? `${undefinedNumber}` : undefined;

  if (precision) {
    myNumber = roundNumber(myNumber, precision);
  }
  if (localisation) {
    return `${i18n.n(myNumber, decimal ? 'decimal' : undefined)}`;
  }
  return `${myNumber}`;
}

export default {
  formatNumber,
};
