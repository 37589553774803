export function currency(value: number, locale: string = 'nl-NL', decimals: number = 2, currency: string | null = 'EUR'): string {
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  };

  if (currency) {
    options.style = 'currency';
    options.currency = currency;
  }

  return new Intl.NumberFormat(locale, options).format(value || 0);
}

export default {
  currency,
};
