import firebase from 'firebase/app';
import { PaymentMethod } from '@/store/models/checkout';
import { Investor } from './user';
import { Asset, AssetRepayment, AssetInvoice, InvoicePaymentStatus, ManualInvoice } from './asset';
import { EffectiveFee, EffectiveWhOrEe } from './fee';

/**
 * The upper db object for an Investment.
 * An investments always contains a subcollection of Payments.
 */
export interface Investment {
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  asset: firebase.firestore.DocumentReference | Asset;
  paidEuroTotal?: number;
  openEuroTotal?: number;
  boughtSharesTotal?: number;
  openSharesTotal?: number;
  totalEuroRepayments?: number;
  invoiceEuroTotal?: number;
  managementFee?: number;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
}

export interface InvestmentRepayment {
  id?: string;
  deleted: boolean;
  amount: number;
  status: InvoicePaymentStatus;
  assetRepayment: firebase.firestore.DocumentReference | AssetRepayment;
  investment: firebase.firestore.DocumentReference | Investment;
  investor: firebase.firestore.DocumentReference | Investor;
  invoice?: firebase.firestore.DocumentReference;
  repaymentDateTime: firebase.firestore.Timestamp;
  createdDateTime: firebase.firestore.Timestamp;
  release: boolean;
  early: boolean;
  fullyRepaid: boolean;
  paymentDateTime?: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
}

export interface InvestmentInvoiceParent {
  id?: string;
  deleted: boolean;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime: firebase.firestore.Timestamp;
  type: 'period' | 'manual' | 'repayment';
  amount: number;
  additionalCents?: number;
  fees?: EffectiveFee[];
  feeAmount: number;
  feeVatAmount: number;
  withholdings?: EffectiveWhOrEe[];
  withholdingAmount: number;
  externalEarnings?: EffectiveWhOrEe[];
  externalEarningAmount: number;
  investment: firebase.firestore.DocumentReference | Investment;
  investor: firebase.firestore.DocumentReference | Investor;
  paymentStatus: InvoicePaymentStatus;
  paymentDateTime?: firebase.firestore.Timestamp;
}

export interface InvestmentPeriodInvoice extends InvestmentInvoiceParent {
  type: 'period';
  assetInvoice: firebase.firestore.DocumentReference | AssetInvoice;
  repayment?: firebase.firestore.DocumentReference | InvestmentRepayment;
  period: {
    start: firebase.firestore.Timestamp;
    end: firebase.firestore.Timestamp;
  };
}

export interface InvestmentManualInvoice extends InvestmentInvoiceParent {
  type: 'manual';
  assetManualInvoice: firebase.firestore.DocumentReference | ManualInvoice;
  title: string;
  expirationDate: firebase.firestore.Timestamp;
}

export interface InvestmentRepaymentInvoice extends InvestmentInvoiceParent {
  type: 'repayment';
  reason: 'missingInterest' | 'lastPeriod',
  assetInvoice: firebase.firestore.DocumentReference | AssetInvoice;
  repayment: firebase.firestore.DocumentReference | InvestmentRepayment;
}

export type InvestmentInvoice = InvestmentPeriodInvoice | InvestmentManualInvoice | InvestmentRepaymentInvoice;

/**
 * Defining all the statuses a Payment can have.
 */
export enum PaymentStatus {
  Open = 'open',
  Canceled = 'canceled',
  Expired = 'expired',
  Paid = 'paid',
  Refunded = 'refunded',
  Requested = 'requested',
}

/**
 * Different payment gateways/providers we use.
 */
export enum PaymentProvider {
  Custom = 'Custom',
  Opp = 'Opp',
}

export const enum ExtendPaymentMethod {
  wallet = 'wallet',
}

/**
 * Actual payment info is placed here.
 */
export interface Payment {
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  investment: firebase.firestore.DocumentReference | Investment;
  asset: firebase.firestore.DocumentReference | Asset;
  dividendsFormat: [string, number];
  provider: PaymentProvider;
  providerData: {
    id?: string;
    method?: PaymentMethod | ExtendPaymentMethod;
    amount: {
      currency: string;
      value: string;
      totalAmount: number;
      feeAmount: number;
    };
    status: PaymentStatus;
    metadata: {
      uid?: string;
      euroAmount: number;
      sharesAmount: number;
      investmentId: string;
      assetId: string;
      paymentId: string;
      selectedDividendsFormatYear?: [string, number];
    };
    _links?: {
      checkout: {
        href: string;
      };
    };
    payFromWallet?: {
      fundAmount: number;
      fundCharge?: string;
      feeAmount: number;
      feeCharge?: string;
    };
  };
  deleted: boolean;
  ended?: firebase.firestore.Timestamp;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
  paymentDateTime?: firebase.firestore.Timestamp;
  requestedDateTime?: firebase.firestore.Timestamp;
  refundedDateTime?: firebase.firestore.Timestamp;
}
