import { Module } from 'vuex';
import to from 'await-to-js';
import ShortUniqueId from 'short-unique-id';
import { functions } from '@/firebase';
import { State } from '@/store/models';
import { Checkout } from '@/store/models/checkout';
import { UserExperience } from '../models/identificationRequest';

const uniqueId = new ShortUniqueId({ length: 20, dictionary: 'alphanum' });

export const UPDATE_CHECKOUT = 'UPDATE_CHECKOUT';
export const RESET_CHECKOUT = 'RESET_CHECKOUT';

export const initCheckoutStore = (initValues?: Partial<Checkout>): Checkout => ({
  sessionId: uniqueId(),
  assetId: initValues?.assetId || '',
  euroAmount: initValues?.euroAmount || 0,
  eurAmount: initValues?.euroAmount || 0,
  selectedDividendsFormatYear: initValues?.selectedDividendsFormatYear || ['', 0],
  sharesAmount: initValues?.sharesAmount || 0,
  totalEmissionCosts: initValues?.totalEmissionCosts || 0,
  totalEur: initValues?.totalEur || 0,
  currentStep: initValues?.currentStep || undefined,
  nextStep: initValues?.nextStep || undefined,
  questionnaireQuestionAndAnswers: initValues?.questionnaireQuestionAndAnswers || [],
  simulationCalcuationResult: initValues?.simulationCalcuationResult || 0,
  netAnnualIncome: 0,
  liquidAssetsValue: 0,
  financialCommitmentsValue: 0,
  experience: initValues?.experience || UserExperience.Unexperienced,
  incomeType: null,
  agressWithRisk: null,
  agressWithRiskWarning: null,
  paymentMethod: null,
});

export default <Module<Checkout, State>>{
  state: initCheckoutStore(),
  mutations: {
    [UPDATE_CHECKOUT](state: Checkout, checkoutObject: Checkout): void {
      Object.assign(state, checkoutObject);
    },
    [RESET_CHECKOUT](state: Checkout, initValue: Partial<Checkout>): void {
      Object.assign(state, initCheckoutStore(initValue));
    },
  },
  actions: {
    async updateCheckoutAction({ commit }, data: Checkout): Promise<void> {
      commit(UPDATE_CHECKOUT, data);
      if ((this.state.checkout as Checkout).assetId && this.state.admin?.checkoutSessionsEnabled) {
        const [createCustomLoginTokenError] = await to(
          functions.httpsCallable('checkoutSessionOperations')({
            action: 'set',
            checkout: this.state.checkout,
          }),
        );
        if (createCustomLoginTokenError) {
          // eslint-disable-next-line no-console
          console.error(`Error updating stored session ${createCustomLoginTokenError.message}`);
        }
      }
    },
    resetCheckoutAction({ commit }, initValue: Partial<Checkout>): void {
      commit(RESET_CHECKOUT, initValue);
    },
  },
  getters: {
    getCheckout(state): Checkout {
      return state;
    },
    getAssetAddress(state, getters): string {
      const asset = getters.getAssetById(state?.assetId);
      if (!asset) {
        return '';
      }

      return asset.street && asset.houseNumber && asset.postalCode && asset.city
        ? `${asset.street} ${asset.houseNumber}, ${asset.postalCode}, ${asset.city}`
        : asset.city;
    },
  },
};
