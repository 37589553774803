import Vue from 'vue';
import Vuex from 'vuex';
// @ts-ignore
import { createModule } from 'vuex-toast';
import modules from '@/store/modules';
import actions from '@/store/actions';
import mutations from '@/store/mutations';
import getters from '@/store/getters';
import { generateInitialRootState, State } from '@/store/models';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  state: generateInitialRootState() as State,
  modules: {
    toast: createModule({
      dismissInterval: 10_000, // default time
    }),
    ...modules,
  },
  actions: {
    ...actions,
  },
  mutations: {
    ...mutations,
  },
  getters: {
    ...getters,
  },
  strict: debug,
});
