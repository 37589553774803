import { Asset } from '@/store/models/asset';
import { Investment } from '@/store/models/investment';
import { Investor } from '@/store/models/user';
import firebase from 'firebase/app';

/**
 * getId (par): string
 * Due to permissions errors we need to limit the vuexfire autoresolve feature. That creates an scenario in which object references
 * in a db object can be now an string, firestore reference or the object itself. This method retrieves the id in all the cases
 * @param par relationship field
 * @returns model id
 */
export function getId(par: string | firebase.firestore.DocumentReference | Asset | Investment | Investor): string {
  if (typeof par === 'string') {
    return par.split('/').pop()!;
  }
  return par?.id || '';
}
