/**
 * Transforming loacels from 'language' format to 'language_TERRITORY' format
 * which is needed for some meta tags
 */

export const transformLocale = (loc: string): string => {
  switch (loc) {
    case 'en':
      return 'en_GB';
    case 'nl':
      return 'nl_NL';
    default:
      return loc;
  }
};
