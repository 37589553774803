import { titleTemplate } from './title-template';
import { transformLocale } from './transform-locale';
import { website } from '../../../whitelabel.config';

export const metaTitles = (title: string, useTemplate: boolean = true): any[] => {
  const fullTitle = useTemplate ? titleTemplate(title) : title;
  return [
    {
      vmid: 'og:title',
      property: 'og:title',
      content: fullTitle,
    },
    {
      vmid: 'twitter:title',
      name: 'twitter:title',
      content: fullTitle,
    },
  ];
};

export const metaDescriptions = (desc: string): any[] => [
  {
    vmid: 'description',
    name: 'description',
    content: desc,
  },
  {
    vmid: 'og:description',
    property: 'og:description',
    content: desc,
  },
  {
    vmid: 'twitter:description',
    name: 'twitter:description',
    content: desc,
  },
];

export const metaURL = (path: string): any[] => {
  // The path argument is destructured from the $route object and has the format
  // '/en/landing' from which we only need the part after the lang parmeter
  const [, , ...rest] = path.split('/');
  const canonicalPath = rest.join('/');
  return [
    {
      vmid: 'og:url',
      property: 'og:url',
      content: `${website}/${canonicalPath}`,
    },
  ];
};

/**
 *
 * @param currentLocale the locale string in which the current page is displayed in
 * @param avaliableLocales array of all the available locales
 *
 * @example metaLocale('en', ['en', 'nl'])
 */
export const metaLocale = (currentLocale: string, avaliableLocales: string[]): any[] => {
  const locales = [
    {
      vmid: 'og:locale',
      property: 'og:locale',
      content: transformLocale(currentLocale),
    },
  ];

  avaliableLocales.forEach((loc: string): void => {
    if (loc !== currentLocale) {
      locales.push({
        vmid: 'og:locale:alternate',
        property: 'og:locale:alternate',
        content: transformLocale(loc),
      });
    }
  });

  return locales;
};

export const metaImage = (url: string, height: string = '600', width: string = '600'): any[] => [
  {
    vmid: 'og:image',
    property: 'og:image',
    content: url,
  },
  {
    vmid: 'og:image:height',
    property: 'og:image:height',
    content: height,
  },
  {
    vmid: 'og:image:width',
    property: 'og:image:width',
    content: width,
  },
  {
    vmid: 'twitter:image',
    property: 'twitter:image',
    content: url,
  },
];

export const linkAlternate = (path: string, avaliableLocales: string[]): any[] => {
  // The path argument is destructured from the $route object and has the format
  // '/en/landing' from which we need both the lang parameter and the rest of the URL
  const [, lang, ...rest] = path.split('/');
  const alternateLinks: any[] = [];

  avaliableLocales.forEach((loc): void => {
    if (loc !== lang) {
      alternateLinks.push({
        rel: 'alternate',
        hreflang: loc,
        href: `${website}/${loc}/${rest.join('/')}`,
      });
    }
  });

  return alternateLinks;
};
