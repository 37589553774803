import firebase from 'firebase/app';

/**
 * Describing the iR status.
 */
export enum IdentificationRequestStatus {
  Initial = 'initial',
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  None = 'none',
}

/**
 * Efficient human gender enum.
 */
export enum UserGender {
  Male = 'm',
  Female = 'f',
}

export enum SocialStatus {
  Officer = 'officer',
  Worker = 'worker',
  Employee = 'employee',
  EarlyRetirement = 'early retirement',
  Retired = 'retired',
  SelfEmployed = 'self-employed',
  Other = 'other',
}

export enum UserExperience {
  Experienced = 'experienced',
  Unexperienced = 'unexperienced',
}

/**
 * Basic iR interface.
 */
export type PrivateIdentification = {
  id?: string;
  bankAccount?: string;
  city: string;
  country: string;
  dateOfBirth: firebase.firestore.Timestamp;
  placeOfBirth?: string;
  gender?: UserGender;
  socialStatus?: string;
  houseNumber: string;
  message?: string;
  name: string;
  nationality: string;
  nationalRegistryNumber?: string;
  passport?: string;
  postalCode: string;
  status: IdentificationRequestStatus;
  streetAddress: string;
  surname: string;
  telephone?: string;
  deleted: boolean;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime: firebase.firestore.Timestamp;
}

/**
 * BusinessIdentification type.
 */
export interface BusinessIdentification extends PrivateIdentification {
  companyId: string;
  kvk: number;
  kvkImage: string;
}

export type IdentificationRequest = PrivateIdentification | BusinessIdentification;

/**
 * Type guard to check the type of Identification Request.
 * @param iRequest basic Identification Request interfaces.
 */
export const isBusiness = (iRequest: IdentificationRequest): iRequest is BusinessIdentification => !!(iRequest as BusinessIdentification).kvk;
