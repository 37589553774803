import { GetterTree } from 'vuex';
import { ComplianceStatus, MerchantStatus, UserTier } from '@/store/models/user';
import { ClientCountry, RetakeQuestionnairePolicies, State } from './models';
import { UserExperience } from './models/identificationRequest';

export default <GetterTree<State, State>> {
  isUserLoggedIn: (state): boolean => state.auth?.uid,

  isOppEnabled: (state): boolean => !!state.admin?.oppIntegration,

  /**
   * Typeguard getter to check if the user is an investor.
   */
  isInvestor: ({ user }): boolean => user?.tier === UserTier.Investor,

  /**
   * Typeguard getter to check if the user is an investor.
   */
  isUser: ({ user }): boolean => user?.tier === UserTier.Account,

  isExperienced: ({ user, admin }): boolean => user?.experience === UserExperience.Experienced && !!admin?.investorPortalConfig.requireExperience,

  hasInvalidMerchantOrCompliance: ({ user }): boolean =>
    !!(user?.oppData && (user?.oppData.merchantStatus !== MerchantStatus.live || user?.oppData.complianceStatus !== ComplianceStatus.verified)),

  hasWalletId: ({ user }, getter): boolean => !getter.isOppEnabled ? true : !!user?.walletId,

  walletIsSetUp: ({ user }, getter): boolean => !getter.isOppEnabled ? true : !!(user?.walletId && !getter.hasInvalidMerchantOrCompliance),

  walletOnReview: ({ user }, getter): boolean => !getter.isOppEnabled ? false : !!((user?.walletId && getter.hasInvalidMerchantOrCompliance)),

  canAnswerQuestionnaire: ({ user, admin }, getters): boolean => {
    if (user) {
      if (!user.questionnaireAnswered) {
        return true;
      }

      switch (admin?.investorPortalConfig.retakeQuestionnairePolicy) {
        case RetakeQuestionnairePolicies.NOT_ALLOWED:
          return false;
        case RetakeQuestionnairePolicies.UNDER_ATTEMPTS:
          return !!user.limitedInvestments && (user.questionnaireAttempts || 0) < 2;
        case RetakeQuestionnairePolicies.EACH_THREE_HOURS:
          if (user.questionnaireDateTime) {
            const now = new Date().getTime();
            const lastAttemptTime = user.questionnaireDateTime.toMillis();
            // Define the threshold for 3 hours in milliseconds
            const threeHours = 3 * 60 * 60 * 1000;
            // Calculate the time difference in milliseconds
            const timeDifference = Math.abs(now - lastAttemptTime);
            return !!user.limitedInvestments && timeDifference >= threeHours;
          }
          return false;
        default:
          return false;
      }
    }

    return false;
  },
};
