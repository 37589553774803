import firebase from 'firebase/app';
import { QuestionnaireAnswers, QuestionnaireScore } from '@/store/models/questionnaire';
import { Pescheckv3PescheckDocument } from './pescheck';
import { Idin, FormattedIdin } from './idin';
import { PrivateIdentification, BusinessIdentification, IdentificationRequestStatus, UserExperience } from './identificationRequest';

/**
 * Defining the different roles for managers.
 */
export enum ManagerRole {
  Superadmin = 'superadmin',
  Admin = 'admin',
  Editor = 'editor',
}

// Workaround for the importing problem as JS object.
export const roles = Object.values(ManagerRole);

/**
 * This is the banhammer interface. Handles the complete disability
 * of the user to interact with the web application.
 */
export enum UserStatus {
  Disabled = 'disabled',
  Enabled = 'enabled',
}

/**
 * Representing our current KYC methods excluding Pescheck.
 */
export enum KYCMethods {
  Idin = 'idin',
  Private = 'private',
  Business = 'business',
}

/**
 * Defining the user's tier and what's his/her account privileges/data.
 */
export enum UserTier {
  Account = 'account',
  Investor = 'investor',
  Trader = 'trader',
}

/* Opp data */

export const enum MerchantStatus {
  new = 'new',
  pending = 'pending',
  live = 'live',
  suspended = 'suspended',
  terminated = 'terminated',
  blocked = 'blocked',
}

export const enum ComplianceStatus {
  unverified = 'unverified',
  pending = 'pending',
  verified = 'verified',
}
export interface OppStatus {
  merchantStatus: MerchantStatus;
  complianceStatus: ComplianceStatus;
  complianceUrl: string;
  requirements: string[];
}

export const enum OppStatusSimplified {
  ERROR = 'error',
  UNVERIFIED = 'unverified',
  LIVE = 'live',
  PENDING = 'pending',
  NONE = 'none',
}

/**
 * Main User interface. At this form, the user is on the 'account' tier since it does not have advanced
 * to any other higher tier.
 */
export interface User {
  bankAccount?: string;
  oppBankAccount?: string;
  createdDateTime: firebase.firestore.Timestamp;
  customId: number;
  email: string;
  deleted: boolean;
  id?: string;
  balance?: number; // Merchant balance
  balanceReserved?: number; // Reserved balance
  /**
   * The 'identificationRequest' indicates that there is an iR but not approved yet / rejected
   */
  identificationRequest?: firebase.firestore.DocumentReference | PrivateIdentification | BusinessIdentification;
  /**
   * The 'idin' property here indicates that the user could have a failed / not completed idin transaction.
   */
  idin?: firebase.firestore.DocumentReference | Idin;
  pescheck?: firebase.firestore.DocumentReference | Pescheckv3PescheckDocument;
  walletId?: string;
  oppData?: OppStatus;
  oppStatus: OppStatusSimplified;
  status: UserStatus;
  statusMessage?: string;
  tier: UserTier;
  questionnaire?: QuestionnaireAnswers;
  quickNotes?: string;
  usedBankAccounts?: { value: string, lastUsed: firebase.firestore.Timestamp }[];
  updatedDateTime: firebase.firestore.Timestamp;
  navStatements?: string[];
  experienceAnswered?: boolean;
  experience?: UserExperience;
  experienceDateTime?: firebase.firestore.Timestamp;
  questionnaireAnswered?: boolean;
  simulationAnswered?: boolean;
  idRequestStatus: IdentificationRequestStatus;
  telephone?: string;
  limitedInvestments?: boolean;
  questionnaireDateTime?: firebase.firestore.Timestamp;
  questionnaireScores?: QuestionnaireScore;
  questionnaireAttempts?: number;
  simulationDateTime?: firebase.firestore.Timestamp;
  questionnaireValidity?: firebase.firestore.Timestamp;
  simulationTestValidity?: firebase.firestore.Timestamp;
  capacity?: number;
}

/**
 * Basic User plus idin data transformed into legible properties.
 */
export interface IdinInvestor extends Omit<User, 'identificationRequest'>, FormattedIdin {
  kycMethod: KYCMethods.Idin;
  tier: UserTier.Investor;
  idin: firebase.firestore.DocumentReference | Idin;
}

/**
 * Basic User plus the private identification data.
 */
export interface PrivateInvestor extends Omit<User, 'idin' | 'bankAccount'>, Omit<PrivateIdentification, 'status'> {
  kycMethod: KYCMethods.Private;
  tier: UserTier.Investor;
  identificationRequest?: firebase.firestore.DocumentReference | PrivateIdentification;
}

/**
 * Basic User plus the business identification data.
 */
export interface BusinessInvestor extends Omit<User, 'idin' | 'bankAccount'>, Omit<BusinessIdentification, 'status'> {
  kycMethod: KYCMethods.Business;
  tier: UserTier.Investor;
  identificationRequest?: firebase.firestore.DocumentReference | BusinessIdentification;
}

/**
 * Discrimination union between all the investor types. The 'kycMethod' field makes the difference.
 * At this point the User is an Investor and has the `tier === UserTier.Investor`.
 */
export type Investor = IdinInvestor | PrivateInvestor | BusinessInvestor;

/**
 * Type guard to check if someone is an investor.
 * @param userOrInvestor basic User or Investor interfaces.
 */
export const isInvestor = (userOrInvestor: User | Investor): userOrInvestor is Investor => userOrInvestor.tier === UserTier.Investor;

/**
 * Data for a bank account change request.
 */
export interface BankAccountChange {
  id?: string;
  bankAccount: string;
  changed: boolean;
  previousBankAccount: string;
  userId: string;
  createdDateTime: firebase.firestore.Timestamp;
}

/**
 * Data for a bank account change request.
 */
export interface DataChangeRequest {
  type: 'name' | 'bankAccount';
  status: 'pending' | 'approved' | 'rejected';
  previousData: { name: string; surname: string; } | { bankAccount: string; }
  newData: { name: string; surname: string; } | { bankAccount: string; }
}
