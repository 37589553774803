/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

module.exports = {
  logo: 'brand.svg',
  logoalt: 'brand-alt.svg',
  logoShare: 'bloqhouse.png',
  scssVariables: './src/scss/variables/brands/_brand.scss',
  name: 'Benkey',
  phone: '(063) 013 96 68',
  email: 'a.dijksterhuis@benkey.nl',
  website: 'https://benkey.nl/',
  glossary: true,
  langFileName: 'brand.ts',
  modules: {
    landing: false,
    identification: true, // setting this to false requires manual changes
  },
  intercom: false,
  requireLegalBanner: true,
  logoUrl: '/account',
  hideRegisterButton: true,
};
