import firebase from 'firebase';
import { Module } from 'vuex';
import { State } from '@/store/models';
import { Payment, PaymentStatus, PaymentProvider } from '@/store/models/investment';
import { formatNumber } from '@/filters/number';
import { PaymentMethod } from '../models/checkout';

export interface InvestmentsArray<T> extends Array<T> {
  totalLength?: number;
}

export interface CombinedDividendsFormat {
  euroAmountDividends: number,
  dividendsFormat: [string, number]
}

/**
 * Function that shows two decimals if there are any
 * @param numb
 */
const showTwoDecimalsOrNone = (numb): number => numb % 1 !== 0 ? Number(formatNumber(numb, 2)) : numb;

// get the paymentDate if paymentDate itself is undefined
export const getPaymentDate = (payment: Payment): firebase.firestore.Timestamp => payment.paymentDateTime ?? payment.updatedDateTime ?? payment.createdDateTime;

export default <Module<Payment[], State>>{
  state: [],
  mutations: {},
  actions: {},
  getters: {
    getPaymentById: (state): Function =>
      (paymentId: string): Payment | undefined => state.find((payment): boolean => payment.id === paymentId),
    getPaymentByAsset: (state): Function =>
      (assetId: string): Payment | undefined =>
        state.find((payment): boolean => payment.asset.id === assetId),
    getPaymentsByInvestmentId: (state): Function =>
      (investmentId: string): Payment[] | undefined => state.filter((payment): boolean => payment.investment.id === investmentId && !payment.deleted),
    getPaidPaymentsByInvestmentId: (state): Function =>
      (investmentId: string): Payment[] | undefined => state.filter(
        (payment): boolean => payment.investment.id === investmentId && payment.providerData.status === PaymentStatus.Paid && !payment.deleted,
      ).sort((a, b): number => getPaymentDate(b).toMillis() - getPaymentDate(a).toMillis()),
    getPaidOrOpenPaymentsByInvestmentId: (state): Function =>
      (investmentId: string): Payment[] | undefined => state.filter(
        (payment): boolean => payment.investment.id === investmentId
          && (payment.providerData.status === PaymentStatus.Paid || payment.providerData.status === PaymentStatus.Open || payment.providerData.status === PaymentStatus.Requested) && !payment.deleted,
      ).sort((a, b): number => getPaymentDate(b).toMillis() - getPaymentDate(a).toMillis()),
    getPaidPayments: (state): Payment[] => state.filter(
      (payment): boolean => payment.providerData.status === PaymentStatus.Paid,
    ),
    getOpenPayments: (state): Payment[] => state.filter(
      (payment): boolean => payment.providerData.status === PaymentStatus.Open || payment.providerData.status === PaymentStatus.Requested,
    ),
    getPspOpenPayments: (state): Payment[] => state.filter(
      (payment): boolean => payment.providerData.status === PaymentStatus.Open && payment.provider !== PaymentProvider.Custom && payment.providerData.method === PaymentMethod.sepa,
    ),
    getPaidOrOpenPayments: (state): Payment[] => state.filter(
      (payment): boolean => payment.providerData.status === PaymentStatus.Paid || payment.providerData.status === PaymentStatus.Open || payment.providerData.status === PaymentStatus.Requested,
    ),
    // Get the number of investments that have at least one paid payment
    getLengthPaidPayments: (state): number => state.filter(
      (payment): boolean => payment.providerData.status === PaymentStatus.Paid,
    ).length,
    getDividendsByAssetYearly: (state): Function =>
      (assetId: string): [string, number][] => state.reduce((divByAsset, payment): [string, number][] => {
        const tempDivByAsset = [...divByAsset];
        if (payment.asset.id === assetId) {
          tempDivByAsset.push([
            payment.dividendsFormat[0],
            showTwoDecimalsOrNone(payment.providerData.metadata.euroAmount * (payment.dividendsFormat[1] / 100)),
          ]);
        }
        return divByAsset;
      }, [] as [string, number][]),
    hasDifferentPaymentFormatsByAsset: (state): Function => (assetId: string): boolean => {
      const dividendFormats: Payment['dividendsFormat'][] = [];
      let whileIndex = 0;
      while (dividendFormats.length < 2 && whileIndex < state.length) {
        const payment = state[whileIndex];
        if (payment.asset.id === assetId) {
          if (!dividendFormats.some((format): boolean => payment.dividendsFormat[0] === format[0] && payment.dividendsFormat[1] === format[1])) {
            dividendFormats.push(payment.dividendsFormat);
          }
        }
        whileIndex++;
      }
      return dividendFormats.length > 1;
    },
  },
};
