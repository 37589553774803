import { Module } from 'vuex';
import { State } from '@/store/models';
import to from 'await-to-js';
import { functions, auth } from '@/firebase';

export default <Module< any, State>>{
  state: {
    comesFromMobile: localStorage.getItem('comesFromMobile') === 'true' || false,
  },
  mutations: {
    setComesFromMobileTrue(state): void {
      state.comesFromMobile = true;
      localStorage.setItem('comesFromMobile', 'true');
    },
  },
  actions: {
    async LoginwithCustomToken({ commit }, { clientIdToken }: { clientIdToken: string }): Promise<void> {
      if (!clientIdToken) {
        return;
      }

      const [createCustomLoginTokenError, createCustomLoginTokenSuccess] = await to(
        functions.httpsCallable('createCustomLoginToken')({ clientIdToken }),
      );
      if (createCustomLoginTokenError || !createCustomLoginTokenSuccess) {
        throw new Error('token error');
      }

      const [error, userCredential] = await to(auth.signInWithCustomToken(createCustomLoginTokenSuccess.data));
      if (error || !userCredential) {
        // eslint-disable-next-line no-console
        console.info(error || userCredential);
        throw new Error('Authentication failed');
      }
      commit('setComesFromMobileTrue');
    },
  },
  getters: {
    userComesFromMobile: (state): boolean => state.comesFromMobile,
  },
};
