import VueI18n from 'vue-i18n';
import to from 'await-to-js';
import Vue from 'vue';

Vue.use(VueI18n);

export type Language = 'nl' | 'en';
export const languages: Language[] = ['nl'];

export const defaultLanguage: Language = 'nl';
export const localStorageKey: string = 'vue-i18n-language';

export const isValidLang = (lang): lang is Language => languages.includes(lang);

export const i18n = new VueI18n({
  locale: defaultLanguage, // set locale
  fallbackLocale: 'nl',
  messages: {}, // set locale messages
  dateTimeFormats: {
    en: {
      expected: {
        year: 'numeric',
        month: 'long',
      },
    },
    nl: {
      expected: {
        year: 'numeric',
        month: 'long',
      },
    },
  },
  numberFormats: {
    en: {
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
      },
    },
    nl: {
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
      },
    },
  },
});

const isAppleWebkit = navigator.userAgent.indexOf('AppleWebKit') !== -1;

// Lazy loading of different language
export async function loadLanguageAsync(lang: Language, formalTranslations?: boolean): Promise<boolean> {
  if (i18n.locale !== lang || formalTranslations !== undefined) {
    if (!i18n.messages[lang]) {
      const [msgsError, msgsSuccess] = await to(import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}${formalTranslations ? '_formal' : ''}.ts`));
      if (msgsError) {
        return false;
      }
      i18n.setLocaleMessage(lang, isAppleWebkit ? Object.freeze(msgsSuccess.default[lang]) : msgsSuccess.default[lang]);
    }
    i18n.locale = lang;
  }
  localStorage.setItem(localStorageKey, lang);
  return true;
}
