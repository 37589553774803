import { modules } from '../../whitelabel.config';

/**
 * Dynamic export of the different modules depending on the whitelabel config
 */
const toExport: { [key: string]: any } = { };
Object.keys(modules).forEach((key): void => {
  if (modules[key]) {
    toExport[key] = require(`./${key}/routes.ts`).default;
  }
});

export default toExport;
