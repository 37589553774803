import { CheckoutStepNames, Step } from '@/store/models/checkout';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { modules } from '../../whitelabel.config';
import { IdentifcationPath } from './checkout';

/*
* Steps in the checkout flow
* The order is the same as it is displayed in
* the identification and contract agreement are depended on the config in whitelabel.config
* */
// eslint-disable-next-line import/no-mutable-exports
export let reservationSteps: Step[] = [
  ...modules.identification
    ? [
      {
        name: CheckoutStepNames.Identification,
        route: `/:lang?/checkout/${IdentifcationPath}/`,
        display: 'settings.identification.identification',
      },
    ]
    : [],
  {
    name: CheckoutStepNames.Experience,
    route: '/:lang?/checkout/experience/',
    display: 'checkout.experience.experience',
  },
  {
    name: CheckoutStepNames.Questionnaire,
    route: '/:lang?/checkout/questionnaire/',
    display: 'checkout.questionnaire.questionnaire',
  },
  {
    name: CheckoutStepNames.RiskQuestionnaire,
    route: '/:lang?/checkout/riskquestionnaire/',
    display: 'checkout.riskquestionnaire.riskquestionnaire',
  },
  {
    name: CheckoutStepNames.Investment,
    route: '/:lang?/checkout/investment/',
    display: 'checkout.fund.selectAnAmount',
  },
  {
    name: CheckoutStepNames.Terms,
    route: '/:lang?/checkout/legal/',
    display: 'checkout.legal.legalAgreement',
  },
  {
    name: CheckoutStepNames.Payment,
    route: '/:lang?/checkout/payment/',
    display: 'common.reservation',
  },
];

export const reloadReservationSteps = (requireQuestionnaire: boolean, requireContractAgreement: boolean, requireExperience: boolean): void => {
  reservationSteps = [
    ...(modules.identification
      ? [
        {
          name: CheckoutStepNames.Identification,
          route: `/:lang?/checkout/${IdentifcationPath}/`,
          display: 'settings.identification.identification',
        },
      ]
      : []),
    ...requireExperience
    ? [
      {
        name: CheckoutStepNames.Experience,
        route: '/:lang?/checkout/experience/',
        display: 'settings.identification.experience.experience',
      },
    ] : [],
    ...requireQuestionnaire
      ? [
        {
          name: CheckoutStepNames.Questionnaire,
          route: '/:lang?/checkout/questionnaire/',
          display: 'checkout.questionnaire.questionnaire',
        },
        {
          name: CheckoutStepNames.RiskQuestionnaire,
          route: '/:lang?/checkout/riskquestionnaire/',
          display: 'checkout.riskquestionnaire.riskquestionnaire',
        },
    ] : [],
    {
      name: CheckoutStepNames.Investment,
      route: '/:lang?/checkout/investment/',
      display: 'checkout.fund.selectAnAmount',
    },
    ...requireContractAgreement
      ? [
        {
          name: CheckoutStepNames.Terms,
          route: '/:lang?/checkout/legal/',
          display: 'checkout.legal.legalAgreement',
        },
      ]
      : [],
    {
      name: CheckoutStepNames.Payment,
      route: '/:lang?/checkout/payment/',
      display: 'common.reservation',
    },
  ];
};
