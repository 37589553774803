import { Module } from 'vuex';
import { State } from '@/store/models';
import { InvestmentInvoice, InvestmentPeriodInvoice } from '@/store/models/investment';
import moment from 'moment';
import BigNumber from 'bignumber.js';

export default <Module<InvestmentInvoice[], State>>{
  state: [],
  mutations: {},
  actions: {},
  getters: {
    getInvoicesByInvestmentId: (state): Function =>
      (investmentId: string): InvestmentInvoice[] | undefined => state.filter((invoice): boolean => invoice.investment.id === investmentId),
    getInvoicesAmountByYear: (state): Function =>
      (): number => state.reduce((sum, invoice): number => {
        if (moment(invoice.createdDateTime.toDate()).format('YYYY') === moment().format('YYYY')) {
          sum = new BigNumber(sum).plus(invoice.amount)
            .plus(invoice.externalEarningAmount || 0)
            .minus(invoice.feeAmount || 0)
            .minus(invoice.withholdingAmount || 0)
            .toNumber();
        }
        return sum;
      }, 0),
    getInvoicesAmountByMonth: (state, getters, rootState): Function => (): number => {
      const amount = rootState.investments.reduce((sum, investment): number => {
        const invoices = getters.getInvoicesByInvestmentId(investment.id) as InvestmentInvoice[];
        const lastInvoice = invoices
          .filter((invoice): boolean => invoice.type === 'period')
          .sort((a, b): number => b.createdDateTime.toMillis() - a.createdDateTime.toMillis())[0];
        if (lastInvoice && moment().diff(lastInvoice.createdDateTime.toDate(), 'month') <= 1) {
          sum = new BigNumber(sum)
            .plus(lastInvoice.amount)
            .plus(lastInvoice.externalEarningAmount || 0)
            .minus(lastInvoice.feeAmount || 0)
            .minus(lastInvoice.withholdingAmount || 0)
            .toNumber();
        }
        return sum;
      }, 0);
      return amount;
    },
    getInvoicesAmountByDay: (state, getters): Function => (): number => {
      const amountByMonth = getters.getInvoicesAmountByMonth();
      return parseFloat((amountByMonth / 30).toFixed(2));
    },
    getLastMonthInvestmentInvoice: (state, getters): Function => (investmentId: string): number => {
      const filteredInvoices = ((getters.getInvoicesByInvestmentId(investmentId) as InvestmentInvoice[])
        .filter((invoice): boolean => invoice.type === 'period') as InvestmentPeriodInvoice[])
        .sort((a, b): number => b.period.start.toMillis() - a.period.start.toMillis());
      return filteredInvoices.length > 0
        ? new BigNumber(filteredInvoices[0].amount)
          .plus(filteredInvoices[0].externalEarningAmount || 0)
          .minus(filteredInvoices[0].feeAmount)
          .minus(filteredInvoices[0].withholdingAmount)
          .toNumber()
        : 0;
    },
  },
};
