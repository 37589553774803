import { Module } from 'vuex';
import { AuthComponents, AuthModal, State } from '@/store/models';
import isAuthPageHelper from '@/helpers/isAuthPageHelper';
import router from '@/router';

export default <Module<AuthModal, State>>{
  state: {
    isOpen: false,
    type: null,
    routeTo: null,
  },
  mutations: {
    setOpen(state, { type, routeTo }: { type: AuthComponents, routeTo?: string }): void {
      state.isOpen = true;
      state.type = type;
      state.routeTo = routeTo;
    },
    setClose(state): void {
      state.isOpen = false;
      state.type = null;
      state.routeTo = null;
    },
  },
  actions: {
    openModal(
      { commit, getters },
      { type, routeTo }: { type: AuthComponents, routeTo?: string },
    ): void {
      if (!isAuthPageHelper(router.currentRoute)) {
        commit('setOpen', {
          type, routeTo,
        });
      }
    },
    closeModal({ commit }): void {
      commit('setClose');
    },
  },
  getters: {
    isModalOpen(state): boolean {
      return state.isOpen;
    },
  },
};
