import { Module } from 'vuex';
import { State } from '@/store/models';
import { InvestmentRepayment } from '@/store/models/investment';

export default <Module<InvestmentRepayment[], State>>{
  state: [],
  mutations: {},
  actions: {},
  getters: {
    getRepaymentsByInvestmentId: (state): Function =>
      (investmentId: string): InvestmentRepayment[] | undefined => state.filter((repayment): boolean => repayment.investment.id === investmentId && !repayment.deleted),
  },
};
